import React from 'react';
import styled from 'styled-components';

import { margins } from '../home/v2/styledComponents';
import { fontSizes, mobileThresholdPixels } from '../../components/home/v3/styledComponents';

const Container = styled.div`
  font-family: Roboto;
  margin: ${margins.xl} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 56px 0px;
  }
`;

const Span = styled.span`
  font-size: ${fontSizes.m};
  line-height: 25px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 20px;
  }
`;

const HowBlock = () => (
  <Container>
    <Span>
      Les algorithmes Fitle prennent en compte la matière et la coupe du vêtement,
      ainsi que les préférences de confort, pour une recommandation personnalisée,
      ce qui améliore de 15% la conversion et réduit de 30% les retours sur
      les e-shops partenaires. Si la bonne taille nécessite quand même des ajustements
      (longueur du pantalon, retouches diverses, etc…), Tilli prend le relai.
      Sa solution, directement intégrée aux sites e-commerce, permet au client
      de réserver en un clic un couturier à domicile pour des retouches, plutôt
      que d’avoir à retourner le vêtement.
    </Span>
  </Container>
);

export default HowBlock;
