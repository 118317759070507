import React from 'react';
import styled from 'styled-components';

import { margins, Container } from '../../components/home/v2/styledComponents';
import { mobileThresholdPixels, colors } from '../../components/home/v3/styledComponents';
import Layout from '../../layouts/index';
import MenuBar from '../../components/home/MenuBar';
import IntroBlock from '../../components/TilliXFitle/IntroBlock';
import PresentationBlock from '../../components/TilliXFitle/PresentationBlock';
import GoalsBlock from '../../components/TilliXFitle/GoalsBlock';
import HowBlock from '../../components/TilliXFitle/HowBlock';
import ShopWordsBlock from '../../components/TilliXFitle/ShopWordsBlock';
import Footer from '../../components/home/v3/Footer';

const Content = styled.div`
  font-family: Roboto;
  color: ${colors.navy};
  padding: 0px 196px;
  ${props => props.grey && `background-color: ${colors.lightGrey}`};
  ${props => props.withMarginTop && `margin-top: ${margins.l}`};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.m};
    ${props => props.withMarginTop && `margin-top: ${margins.m}`};
  }
`;

class TilliXFitle extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    return (
      <Layout routeSlug="TilliXFitle">
        <Container>
          <MenuBar />
          <Content grey withMarginTop><IntroBlock /></Content>
          <Content><PresentationBlock /></Content>
          <Content grey><GoalsBlock /></Content>
          <Content><HowBlock /></Content>
          <Content grey><ShopWordsBlock /></Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default TilliXFitle;
