import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { margins } from '../home/v2/styledComponents';
import { mobileThresholdPixels, fontSizes } from '../../components/home/v3/styledComponents';

import icon from '../../assets/tillixfitle/tape.icon.png';

const Container = styled.div`
  margin: ${margins.xl} 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 56px 0px 0px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0px ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: 0px 0px ${margins.l};
  }
`;

const FitleBlock = styled.div`
  flex: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.m};
  }
`;
const TilliBlock = styled.div`
  flex: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.m};
  }
`;

const Title3 = styled.h3`
  font-weight: 500;
  font-size: 26px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
  }
`;

const Img = styled.img`
  margin-right: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.m};
  }
`;

const Span = styled.span`
  font-size: ${fontSizes.m};
  line-height: 25px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 20px;
  }
`;

const LineV = styled.div`
  width: 0px;
  height: 260px;
  border: 1px solid rgba(23, 44, 66, 0.32);
  margin: 1px ${margins.l} -${margins.m};
`;

const LineH = styled(LineV)`
  height: 0px;
  width: 260px;
  margin: ${margins.s} 0px ${margins.m};
`;

const PresentationBlock = ({ context: { isMobile } }) => (
  <Container>
    <SubContainer>
      <Img src={icon} alt="ruban de mesure" />
      <Span>
        On dit souvent que ce n’est pas la taille qui compte… Eh bien si !
        Qui n’a jamais eu peur de se tromper de taille en achetant ses vêtements
        sur Internet ou a été déçu en essayant chez lui son vêtement ?
        Tilli & Fitle décident donc de réunir leurs forces pour apporter une
        solution complète aux plateformes e-commerce.
      </Span>
    </SubContainer>
    {isMobile && <LineH />}
    <SubContainer>
      <FitleBlock>
        <Title3>Qu’est-ce que Fitle ? </Title3>
        <Span>
          Fitle est la solution de recommandation de taille leader sur le marché.
          En intégrant un bouton « Découvrir ma taille » directement sur chaque
          page produit des e-shops partenaires, Fitle rassure les utilisateurs
          au moment décisif de la sélection de la taille en leur indiquant la
          taille la mieux adaptée à leur morphologie, en fonction du taillant de
          la marque.
        </Span>
      </FitleBlock>
      {!isMobile && <LineV />}
      <TilliBlock>
        <Title3>Qu’est-ce que Tilli ? </Title3>
        <Span>
          Une solution plug & play qui s’intègre au parcours du e-commerçant
          permettant à leurs clients de réserver un couturier à domicile pour
          leurs retouches. Jusqu’à présent en e-commerce on proposait soit au
          client de repasser en magasin soit de se débrouiller pour ses retouches.
          Le résultat ? Les gens renvoient leur colis. Tilli décide d’apporter
          l’expérience des retouches au domicile des clients pour prolonger
          l’expérience e-commerce.
        </Span>
      </TilliBlock>
    </SubContainer>
  </Container>
);

PresentationBlock.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(PresentationBlock);
