import React from 'react';
import styled from 'styled-components';

import { margins } from '../home/v2/styledComponents';
import { fontSizes, mobileThresholdPixels } from '../../components/home/v3/styledComponents';

const Container = styled.div`
  font-family: Roboto;
  margin: ${margins.xl} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 56px 0px;
  }
`;

const Span1 = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.l};
  line-height: 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    line-height: 31px;
  }
`;

const AuthorBlock = styled.div`
  margin-top: ${margins.m};
  text-align: right;
`;

const Span2 = styled.span`
  font-size: ${fontSizes.m};
  line-height: 23px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 18px;
  }
`;

const ShopWordsBlock = () => (
  <Container>
    <Span1>
      {"“ Nous nous réjouissons du partenariat entre Tilli et Fitle afin d'offrir à "}
      nos clientes e-shop une expérience unique en simplifiant le choix de la
      taille et la possibilité de faire retoucher son vêtement grâce à des
      retoucheurs expérimentés chez soi.&nbsp;”
    </Span1>
    <AuthorBlock><Span2>assure Charles de Balzac Paris</Span2></AuthorBlock>
  </Container>
);

export default ShopWordsBlock;
